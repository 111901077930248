import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { BaseService } from './base.service';
import { UserService } from '../user-detail/user.service';
import { MetaService } from './meta.service';
import { zip } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Base } from '../classes/base';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    constructor(private http: HttpClient, 
                private base: BaseService, 
                private userService: UserService,
                private meta: MetaService,
                private trans: TranslateService) { }

    login(data, toTelegram?): Observable<any> {
        this.base.setRootUrl( 'https://api.cerberus.sofenty.com' );
        const url = toTelegram ? 'api-telegram-auth' : 'api-token-auth/';
        return this.http.post<any>(this.base.getRootUrl() + url, data).pipe(
            tap(_ => {
                this.base.log('login');
            }),
            catchError(this.base.handleError('login', []))
        );
    }

    getDomain(login): Observable<any> { 
        return this.http.get<any>(`https://chat.woki.one/domain?Login=${login}`).pipe(
            tap(_ => {
                this.base.log('getDomain');
            }),
            catchError(this.base.handleError('getDomain', []))
       );
    }

    authenticated(): boolean {
        return this.base.getToken() && Base.getCurrentUser() ? true : false;
    }

    refreshTablesClasses(): Observable<any> {
        return zip(this.meta.getTablesMeta(), this.base.getClasses());
    }

    loginAuth(app, login, password, step, result) {
        this.login({username: login, password}, step === 2).subscribe(resp => {
            if (resp.token) {
                this.successLogin(resp.token, app, result);
            } else {
                result.next(false);
                const error = resp.error ? resp.error : this.trans.instant('input-correct-login-and-password');
                this.base.sendToast(`${this.base.getError(error)}`);
            }
        }, (_) => {
            this.base.sendToast(this.trans.instant('input-correct-login-and-password'));
            result.next(false);
        });
    }

    successLogin(token, app, result) {
        this.base.setToken(token);
        this.userService.getUser().subscribe(users => {
            if (users.data) {
                users = users.data;
            }
            const user = users[0];
            this.base.setCurrentUser(user);
            app.isAuth = true;
            app.changed = true;
            this.refreshTablesClasses().subscribe(_ => {
                result.next(true);
                this.base.auth.next(0);
            });
        });
    }
}
